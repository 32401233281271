import { Row } from "react-bootstrap";
import styled from "styled-components";
import { breakpoints } from "../../themes/commonTheme";

export const StyledSpan = styled.span`
  color: ${(props) => props.theme.primary};
`;

export const StyledParahraph = styled.p`
  font-weight: 700;
  margin: 20px 0;
`;

export const StyledTitle = styled.h1`
  padding: 0;

  ${breakpoints.down("md")} {
    padding: 0 0 40px;
  }
`;

export const StyledContentWrapper = styled.div`
  padding: 20px 0 10px 20px;

  ${breakpoints.down("md")} {
    padding: 0 0 10px;
  }
`;

export const StyledRow = styled(Row)`
  padding: 85px 0;

  ${breakpoints.down("md")} {
    padding: 45px 0;
  }
`;

export const StyledImg = styled.div`
  background: center center no-repeat;
  height: 428px;
  background-size: cover;
`;
