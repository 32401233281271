import { Container } from "react-bootstrap";
import Hero from "../../components/Hero/Hero";

import hero from "../../assets/hero/our-services/our-services.jpg";
import AboutUsComponent from "../../components/AboutUsComponent/AboutUsComponent";

const OurServices = () => {
  return (
    <>
      <Hero hero={hero} heroOnly position="right" />
      <Container className="px-4 px-md-3">
        <AboutUsComponent ourServices />
      </Container>
    </>
  );
};

export default OurServices;
