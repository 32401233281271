import Router from "./Router";
import "bootstrap/dist/css/bootstrap.min.css";

import { Container } from "react-bootstrap";
import Nav from "./components/Nav/Nav";
import Footer from "./components/Footer/Footer";

const App = () => {
  return (
    <div className="super-container">
      <Container fluid className="p-0">
        <Container className="container-margin-top">
          <Nav />
        </Container>
        <Router />
      </Container>
      <Container fluid>
        <Footer />
      </Container>
    </div>
  );
};

export default App;
