import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";

import ScrollToTop from "./ScrollToTop";
import AboutUS from "./pages/AboutUs/AboutUs";
import OurServices from "./pages/OurServices/OurServices";
import OurPortfolio from "./pages/OurPortfolio/OurPortfolio";
import SusDev from "./pages/SusDev/SusDev";
import ContactUs from "./pages/ContactUs/ContactUs";

const Router = () => {
  return (
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUS />} />
        <Route path="/our-services" element={<OurServices />} />
        <Route path="/our-portfolio" element={<OurPortfolio />} />
        <Route path="/sustainable-development" element={<SusDev />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
    </ScrollToTop>
  );
};

export default Router;
