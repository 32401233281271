import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { breakpoints } from "../../themes/commonTheme";

export const StyledSpan = styled.span`
  color: ${(props) => props.theme.primary};
  text-transform: uppercase;
`;

export const StyledRow = styled(Row)`
  padding: 65px 0;

  ${breakpoints.down("md")} {
    padding: 45px 0;
  }
`;

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:first-of-type {
    ${breakpoints.down("md")} {
      margin-bottom: 60px;
    }
  }
`;

export const StyledColAboutUs = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 30px;

  &:first-of-type {
    ${breakpoints.down("md")} {
      margin-bottom: 60px;
    }
  }
`;

export const StyledTitle = styled.h2`
  margin: 27px 0 35px;
`;

export const StyledParagraph = styled.p`
  text-align: center;
  padding: 0 44px;
`;

export const StyledParagraphAboutUs = styled.p`
  padding: 0;
`;
