import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import {
  StyledRow,
  StyledFooterContainer,
  StyledContentContainer,
  StyledFooterContent,
  StyledEmailLink,
  StyledLabel,
  StyledLink,
} from "./Footer.styled";

const Footer = () => {
  return (
    <>
      <StyledRow>
        <Col className="p-0">
          <StyledFooterContainer>
            <Container className="px-4 px-md-3">
              <Row>
                <Col>
                  <StyledContentContainer>
                    <Row>
                      <Col xs={12} md={4}>
                        <StyledFooterContent>
                          2024 &copy; All rights reserved
                        </StyledFooterContent>
                      </Col>
                      <Col xs={12} md={4}>
                        <StyledFooterContent>
                          <StyledLabel>Email</StyledLabel>
                          <StyledEmailLink href="mailto:office@sbcl.co">
                            office@sbcl.co
                          </StyledEmailLink>
                        </StyledFooterContent>
                      </Col>
                      <Col xs={12} md={4}>
                        <StyledFooterContent>
                          <StyledLabel>Location</StyledLabel>
                          <StyledLink href="https://maps.app.goo.gl/Zke153vfwTCTUmRk8">
                            <span>Stasikratous 16 1065,</span>{" "}
                            <span>Nicosia, Cyprus</span>
                          </StyledLink>
                        </StyledFooterContent>
                      </Col>
                    </Row>
                  </StyledContentContainer>
                </Col>
              </Row>
            </Container>
          </StyledFooterContainer>
        </Col>
      </StyledRow>
    </>
  );
};

export default Footer;
