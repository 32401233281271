import { Container } from "react-bootstrap";
import Hero from "../../components/Hero/Hero";

import hero from "../../assets/hero/contact/contact.jpg";
import AboutUsComponent from "../../components/AboutUsComponent/AboutUsComponent";

const ContactUs = () => {
  return (
    <>
      <Hero hero={hero} heroOnly position="center" />

      <Container className="px-4 px-md-3">
        <AboutUsComponent contactUs />
      </Container>
    </>
  );
};

export default ContactUs;
