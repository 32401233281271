import React from "react";
import { Col, Row } from "react-bootstrap";

import {
  StyledContentWrapper,
  StyledImg,
  StyledParahraph,
  StyledRow,
  StyledSpan,
  StyledTitle,
} from "./Hero.styled";

import { StyledImgContainer } from "../OurServices/OurServices.styled";

const Hero = ({ withText, hero, heroOnly, position }) => {
  return (
    <>
      {withText && (
        <>
          <StyledRow>
            <Col className="pe-0" xs={12} md={6}>
              <StyledTitle>
                We make your <StyledSpan>bussines</StyledSpan> better for you.
              </StyledTitle>
            </Col>
            <Col xs={12} md={6}>
              <StyledContentWrapper>
                <p>Before we start, we would like to present who we are.</p>
                <StyledParahraph>
                  S.B.C.L stands for Security Biometrics Consulting Leaders.
                </StyledParahraph>
                <p>
                  We are a group assembling experts from areas of Digital
                  Identity, Biometrics solutions, and international trade and
                  bilateral relations covering primarily European and Asian
                  markets.
                </p>
              </StyledContentWrapper>
            </Col>
          </StyledRow>
          <Row>
            <Col>
              <StyledImgContainer>
                <StyledImg style={{ backgroundImage: `url(${hero})` }} />
              </StyledImgContainer>
            </Col>
          </Row>
        </>
      )}
      {heroOnly && (
        <StyledImgContainer>
          <StyledImg
            style={{
              backgroundImage: `url(${hero})`,
              backgroundPosition: `${position}`,
            }}
          />
        </StyledImgContainer>
      )}
    </>
  );
};

export default Hero;
