export const breakpoints = {
  xs: "0",
  tiny: "375px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1440px",

  up: (size) => `@media only screen and (min-width: ${breakpoints[size]})`,
  down: (size) => `@media only screen and (max-width: ${breakpoints[size]})`,
  between: (down, up) =>
    `@media only screen and (max-width: ${breakpoints[down]}) and (min-width:${breakpoints[up]}) `,
};

const themes = {
  background: "#FDFDFF", // White background for cleanliness

  primary: "#E4172B", // red
  navigationBGColor: "#E4172B", // navigation background color
  navigationLinkHoverColorDark: "#8a0100",
  exploreNowBgColor: "#474646",
  black50: "rgba(0, 0, 0, 0.5)",
  blackColor: "#000",
  red50: "rgba(228, 23, 43, 0.5)",
  borderBGColor: "#474646",
  pageTitleColor: "#716F70",
};

export default themes;
