import React from "react";
import {
  StyledCol,
  StyledRow,
  StyledSpan,
  StyledTitle,
  StyledParagraph,
  StyledColAboutUs,
  StyledParagraphAboutUs,
} from "./VisionMission.styled";
import vision from "../../assets/vision-mission/vision.svg";
import mission from "../../assets/vision-mission/mission.svg";

const VisionMission = ({ visionMissionHome, visionMissionAboutUs }) => {
  return (
    <>
      {visionMissionHome && (
        <StyledRow>
          <StyledCol xs={12} md={6}>
            <img src={vision} alt="house" />
            <StyledTitle>
              Our <StyledSpan>Vision</StyledSpan>
            </StyledTitle>
            <StyledParagraph>
              Experience and global market oversights are something to be shared
              for the benefit of all.
            </StyledParagraph>
            <StyledParagraph>
              Our vision and our aims are to use knowledge to make a difference
              for our clients.
            </StyledParagraph>
          </StyledCol>
          <StyledCol xs={12} md={6}>
            <img src={mission} alt="house" />
            <StyledTitle>
              Our <StyledSpan>Mission</StyledSpan>
            </StyledTitle>
            <StyledParagraph>
              We provide various range of services for our customers; making
              their businesses more successful, we conquer global markets
              together, and we improve their results and performance wherever is
              needed.
            </StyledParagraph>
          </StyledCol>
        </StyledRow>
      )}
      {visionMissionAboutUs && (
        <StyledRow>
          <StyledColAboutUs xs={12} md={6}>
            <img src={vision} alt="house" />
            <StyledTitle>
              Our <StyledSpan>Vision</StyledSpan>
            </StyledTitle>
            <StyledParagraphAboutUs>
              Experience and global market oversights are something to be shared
              for the benefit of all.
            </StyledParagraphAboutUs>
            <StyledParagraphAboutUs>
              Our vision and our aims are to use knowledge to make a difference
              for our clients.
            </StyledParagraphAboutUs>
          </StyledColAboutUs>
          <StyledColAboutUs xs={12} md={6}>
            <img src={mission} alt="house" />
            <StyledTitle>
              Our <StyledSpan>Mission</StyledSpan>
            </StyledTitle>
            <StyledParagraphAboutUs>
              We provide various range of services for our customers; making
              their businesses more successful, we conquer global markets
              together, and we improve their results and performance wherever is
              needed.
            </StyledParagraphAboutUs>
          </StyledColAboutUs>
        </StyledRow>
      )}
    </>
  );
};

export default VisionMission;
