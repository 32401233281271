import { createGlobalStyle, css } from "styled-components";
import { breakpoints } from "./themes/commonTheme";

export default createGlobalStyle`${css`
  body {
    background-color: ${(props) => props.theme.background};
    font-size: 16px;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "slnt" 0;
  }

  .container-margin-top {
    margin-top: 88px;
  }

  a:hover {
    color: unset;
  }

  .sticky-navbar-nav {
    background-color: ${(props) => props.theme.navigationBGColor} !important;
  }

  .active {
    color: ${(props) => props.theme.navigationLinkHoverColorDark} !important;
    font-weight: 700;
    text-decoration: underline !important;
    text-underline-offset: 5px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-variation-settings: "slnt" 0;
  }

  h1 {
    font-size: 55px;
    line-height: 82.5px;

    ${breakpoints.down("md")} {
      font-size: 45px;
      line-height: 50.5px;
    }
  }

  h2 {
    font-size: 30px;
    line-height: 39px;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.4;
  }

  h4 {
    font-size: 1.5rem;
    line-height: 1.5;
  }

  h5 {
    font-size: 1.25rem;
    line-height: 1.6;
  }

  h6 {
    font-size: 1rem;
    line-height: 1.7;
  }

  p {
    font-size: 18px;
    line-height: 23.4px;
    margin-bottom: 0;
  }
`}`;
