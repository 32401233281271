import React from "react";
import {
  StyledContentContainer,
  StyledTitle,
  StyledParagraphContainer,
  StyledWrapper,
  StyledEmailWrapper,
  StyledLocationWrapper,
  StyledIcon,
  StyledContent,
  StyledLink,
} from "./AboutUsComponent.styled";
import { Col, Row } from "react-bootstrap";
import VisionMission from "../VisionMission/VisionMission";

import email from "../../assets/contact/email.svg";
import pin from "../../assets/contact/pin.svg";

const AboutUsComponent = ({
  aboutUs,
  ourServices,
  ourPortfolio,
  susDev,
  contactUs,
}) => (
  <>
    {aboutUs && (
      <Row>
        <Col md={{ span: 7, offset: 4 }}>
          <StyledContentContainer>
            <StyledTitle>
              About <span>Us</span>
            </StyledTitle>
            <StyledParagraphContainer>
              <p>
                Welcome to our website. Before we start, we would like to
                present who we are.
              </p>
              <p className="font-bold">
                S.B.C.L stands for Security Biometrics Consulting Leaders.
              </p>
              <p>
                We are a group assembling experts from areas of Digital
                Identity, Biometrics solutions, and international trade and
                bilateral relations covering primarily European and Asian
                markets.
              </p>
              <p>
                Our company may be a newcomer, but our joint professional
                experiences in these areas and markets have lasted more than 20
                years, including multi-million projects and contracts in both
                governmental and industry affairs.
              </p>
              <p>
                Focusing specifically on highlighted industries, we have managed
                to be in direct cooperation with all major global companies,
                involving us in more than 100 international contracts and
                agreements.
              </p>
            </StyledParagraphContainer>
          </StyledContentContainer>
          <VisionMission visionMissionAboutUs />
        </Col>
      </Row>
    )}
    {ourServices && (
      <Row>
        <Col md={{ span: 7, offset: 4 }}>
          <StyledContentContainer>
            <StyledTitle>
              Our <span>Services</span>
            </StyledTitle>
            <StyledParagraphContainer>
              <p>
                Knowledge and know-how make for a responsible approach - guiding
                our clients on global markets Individual solutions for
                individual needs - understanding the specifics of each of our
                client Technical support - making complex technical challenges
                solvable for our clients Optimizing supply chain communication
                on demanding markets.
              </p>
            </StyledParagraphContainer>
          </StyledContentContainer>
        </Col>
      </Row>
    )}
    {ourPortfolio && (
      <Row>
        <Col md={{ span: 7, offset: 4 }}>
          <StyledContentContainer>
            <StyledTitle>
              Our <span>Portfolio</span>
            </StyledTitle>
            <StyledParagraphContainer>
              <p>
                We have had the pleasure of supporting some of the most
                recognized companies across a range of sectors by giving them
                resources and support to build a long-term business operation.
              </p>
              <p>We are delighted to start a new journey together with them.</p>
              <p>
                Our main focus are currently niche markets such are: information
                technologies, identity solutions and related hardware and
                software applications, but also in renewable energy.
              </p>
            </StyledParagraphContainer>
          </StyledContentContainer>
        </Col>
      </Row>
    )}
    {susDev && (
      <Row>
        <Col md={{ span: 7, offset: 4 }}>
          <StyledContentContainer>
            <StyledTitle>
              Sustainable <span>development</span>
            </StyledTitle>
            <StyledParagraphContainer>
              <p>
                We are driven by our strong will to be part of the world and
                businesses sharing the same views.
              </p>
              <p>
                We honor not only strategic decisions but also the daily
                operations of our partners where their development truly meets
                the needs of the present without compromising the ability of
                future generations to meet their own needs. Our corporate
                culture puts a high bar here, as we do believe we are here to do
                something today for the better tomorrow.
              </p>
            </StyledParagraphContainer>
          </StyledContentContainer>
        </Col>
      </Row>
    )}
    {contactUs && (
      <Row>
        <Col md={{ span: 7, offset: 4 }}>
          <StyledContentContainer>
            <StyledTitle>
              Contact <span>Us</span>
            </StyledTitle>
            <StyledWrapper>
              <StyledEmailWrapper>
                <StyledIcon>
                  <img src={email} alt="email" />
                </StyledIcon>
                <StyledContent>
                  <h2>E-mail</h2>
                  <StyledLink href="mailto:office@sbcl.co">
                    office@sbcl.co
                  </StyledLink>
                </StyledContent>
              </StyledEmailWrapper>
              <StyledLocationWrapper>
                <StyledIcon>
                  <img src={pin} alt="pin" />
                </StyledIcon>
                <StyledContent>
                  <h2>Location</h2>
                  <StyledLink href="https://maps.app.goo.gl/Zke153vfwTCTUmRk8">
                    <span>Stasikratous 16</span>
                    <span>1065, Nicosia, Cyprus</span>
                  </StyledLink>
                </StyledContent>
              </StyledLocationWrapper>
            </StyledWrapper>
          </StyledContentContainer>
          <VisionMission visionMissionAboutUs />
        </Col>
      </Row>
    )}
  </>
);

export default AboutUsComponent;
