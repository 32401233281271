import { Row } from "react-bootstrap";
import styled from "styled-components";
import { breakpoints } from "../../themes/commonTheme";

export const StyledRow = styled(Row)`
  padding: 30px 0 0;

  ${breakpoints.down("md")} {
    padding: 0;
  }
`;

export const StyledFooterContainer = styled.div`
  background-color: ${(props) => props.theme.exploreNowBgColor};
  padding: 75px 0 35px;
  width: 100%;
`;

export const StyledContentContainer = styled.div`
  border-top: 1px solid ${(props) => props.theme.background};
`;

export const StyledFooterContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.background};
  font-size: 14px;
`;

export const StyledEmailLink = styled.a`
  display: block;
  text-decoration: none;
  color: ${(props) => props.theme.background};

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.primary};
  }
`;

export const StyledLabel = styled.div`
  font-size: 14px;
`;

export const StyledLink = styled(StyledEmailLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
