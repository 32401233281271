import { Container } from "react-bootstrap";
import Hero from "../../components/Hero/Hero";

import hero from "../../assets/hero/about-us/about-us.png";
import AboutUsComponent from "../../components/AboutUsComponent/AboutUsComponent";

const AboutUS = () => {
  return (
    <>
      <Hero hero={hero} heroOnly position="center" />
      <Container className="px-4 px-md-3">
        <AboutUsComponent aboutUs />
      </Container>
    </>
  );
};

export default AboutUS;
