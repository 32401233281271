import React from "react";
import { StyledButton } from "./CustomBtn.styled";
import { TbArrowNarrowRight } from "react-icons/tb";

const CustomBtn = ({ label, onClick }) => (
  <StyledButton label={label} onClick={onClick}>
    {label}{" "}
    <span className="ms-2">
      <TbArrowNarrowRight size={"1.8em"} />
    </span>
  </StyledButton>
);

export default CustomBtn;
