import styled from "styled-components";
import { breakpoints } from "../../themes/commonTheme";

export const StyledContentContainer = styled.div`
  border-left: 1px solid ${(props) => props.theme.borderBGColor};
  padding: 20px 0 0 20px;
`;

export const StyledTitle = styled.h1`
  font-size: 60px;
  line-height: 90px;
  font-weight: 400;
  text-transform: uppercase;
  color: ${(props) => props.theme.pageTitleColor};

  span {
    display: block;
    font-weight: 700;
    color: ${(props) => props.theme.primary};
  }

  ${breakpoints.down("md")} {
    font-size: 40px;
    line-height: 50px;
    padding-top: 30px;
  }
`;

export const StyledParagraphContainer = styled.div`
  position: relative;
  margin-top: 30px;

  p {
    margin-bottom: 20px;
  }

  .font-bold {
    font-weight: 700;
  }
`;

export const StyledLocationWrapper = styled.div`
  display: flex;
  margin-top: 35px;
`;

export const StyledEmailWrapper = styled.div`
  display: flex;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 75px 0;
`;
export const StyledIcon = styled.div`
  padding: 30px 40px;
`;
export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`;
export const StyledLink = styled.a`
  text-decoration: none;
  font-size: 20px;
  line-height: 26px;
  color: ${(props) => props.theme.blackColor};

  &:hover {
    color: ${(props) => props.theme.primary};
  }

  span {
    display: block;
  }
`;
