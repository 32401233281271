import styled from "styled-components";
import { breakpoints } from "../../themes/commonTheme";

export const StyledContainer = styled.div`
  position: relative;

  ${breakpoints.down("md")} {
    margin-bottom: 30px;
  }
`;

export const StyledContentContainer = styled.a`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-decoration: none;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${(props) => props.theme.black50};
  }

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -35px;
    right: -35px;
    background: ${(props) => props.theme.red50};
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover:after {
    transform: scale(51);
  }
`;

export const StyledLabel = styled.p`
  color: ${(props) => props.theme.background};
  font-size: 30px;
  text-align: center;
  line-height: 32px;
  font-weight: 500;
  position: relative;
  z-index: 2;

  ${breakpoints.down("md")} {
    font-size: 25px;
  }
`;
