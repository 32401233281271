export const navigation = [
  {
    label: "Home",
    url: "/",
  },
  {
    label: "About us",
    url: "/about-us",
  },
  {
    label: "Our services",
    url: "/our-services",
  },
  {
    label: "Our portfolio",
    url: "/our-portfolio",
  },
  {
    label: "Sustainable development",
    url: "/sustainable-development",
  },
  {
    label: "Contact",
    url: "/contact",
  },
];
