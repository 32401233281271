import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { breakpoints } from "../../themes/commonTheme";

export const StyledSpan = styled.span``;

export const StyledRow = styled(Row)`
  padding: 65px 0 30px;

  ${breakpoints.down("md")} {
    padding: 15px 0 65px;
  }
`;

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledTitle = styled.h2`
  color: ${(props) => props.theme.primary};
  text-transform: uppercase;
  font-size: 60px;
  line-height: 78px;
  width: 60%;
  margin-bottom: 16px;

  ${breakpoints.down("md")} {
    font-size: 45px;
    line-height: 60px;
    width: 100%;
  }
`;

export const StyledParagraph = styled.p`
  font-size: 20px;
  margin: 20px 0;
  line-height: 26px;
`;

export const StyledContentWrapper = styled.div`
  padding: 0 0 0 50px;

  ${breakpoints.down("md")} {
    padding: 35px 0 0 0;
  }
`;

export const StyledBtnWrapper = styled.div`
  margin-top: 30px;
`;

export const StyledImgContainer = styled.div`
  overflow: hidden;

  img {
    max-width: unset;
    vertical-align: unset;
    float: inline-end;
  }
`;
