import React from "react";
import {
  StyledContainer,
  StyledContentContainer,
  StyledLabel,
} from "./HoverCards.styled";

import portfolio from "../../assets/hover-cards/portfolio.png";
import susDev from "../../assets/hover-cards/sus-dev.png";

import { Col, Row } from "react-bootstrap";

const HoverCards = () => {
  return (
    <>
      <Row>
        <Col xs={12} md={7}>
          <StyledContainer>
            <img className="img-fluid" src={portfolio} alt="portfolio" />
            <StyledContentContainer href="/our-portfolio">
              <StyledLabel>Our portfolio</StyledLabel>
            </StyledContentContainer>
          </StyledContainer>
        </Col>
        <Col xs={12} md={5}>
          <StyledContainer>
            <img className="img-fluid" src={susDev} alt="susDev" />
            <StyledContentContainer href="/sustainable-development">
              <StyledLabel>Sustainable development</StyledLabel>
            </StyledContentContainer>
          </StyledContainer>
        </Col>
      </Row>
    </>
  );
};

export default HoverCards;
