import React from "react";
import {
  StyledCol,
  StyledRow,
  StyledTitle,
  StyledParagraph,
  StyledContentWrapper,
  StyledBtnWrapper,
  StyledImgContainer,
} from "./OurServices.styled";

import ourSrvices from "../../assets/our-services/our-services.jpg";
import { Col } from "react-bootstrap";
import CustomBtn from "../CustomBtn/CustomBtn";
import { useNavigate } from "react-router-dom";

const OurServices = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/our-services");
  };
  return (
    <>
      <StyledRow>
        <Col xs={12} md={5}>
          <StyledImgContainer>
            <img className="img-fluid" src={ourSrvices} alt="our services" />
          </StyledImgContainer>
        </Col>
        <StyledCol xs={12} md={7}>
          <StyledContentWrapper>
            <StyledTitle>Our services</StyledTitle>
            <StyledParagraph>
              Knowledge and know-how make for a responsible approach - guiding
              our clients on global markets.
            </StyledParagraph>
            <StyledParagraph>
              Individual solutions for individual needs - understanding the
              specifics of each of our client.
            </StyledParagraph>
            <StyledBtnWrapper>
              <CustomBtn label="Explore Now" onClick={handleClick} />
            </StyledBtnWrapper>
          </StyledContentWrapper>
        </StyledCol>
      </StyledRow>
    </>
  );
};

export default OurServices;
