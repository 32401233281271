import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  StyledLogoWrapper,
  StyledNav,
  StyledDiv,
  StyledUl,
} from "./Nav.styled";

import StickyNavbar from "react-sticky-navbar";

import logo from "../../assets/logo/logo.png";
import { Col, Row } from "react-bootstrap";
import { navigation } from "./model/navigation";

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuToggle = () => setMenuOpen(!menuOpen);
  const closeMenu = () => setMenuOpen(false);

  const navigationData = navigation || [];

  return (
    <Row>
      <Col>
        <StickyNavbar showOnScrollDown>
          <StyledNav role="navigation">
            <StyledDiv className="hamburger-menu">
              <input
                type="checkbox"
                id="menu__toggle"
                checked={menuOpen}
                onChange={handleMenuToggle}
              />
              <label className="menu__btn" for="menu__toggle">
                <span></span>
              </label>

              <StyledUl className="menu__box">
                {navigationData.length &&
                  navigationData.map(({ label, url }, index) => (
                    <li key={index}>
                      <NavLink
                        index={`${index} - ${label}`}
                        className="nav-link menu__item"
                        to={url}
                        activeclassname="active"
                        onClick={closeMenu}
                      >
                        {label}
                      </NavLink>
                    </li>
                  ))}
              </StyledUl>
            </StyledDiv>
            <StyledLogoWrapper>
              <a href="/">
                <img src={logo} alt="logo" />
              </a>
            </StyledLogoWrapper>
          </StyledNav>
        </StickyNavbar>
      </Col>
    </Row>
  );
};

export default Nav;
