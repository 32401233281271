import { Container } from "react-bootstrap";
import Hero from "../../components/Hero/Hero";
import VisionMission from "../../components/VisionMission/VisionMission";
import OurServices from "../../components/OurServices/OurServices";
import HoverCards from "../../components/HoverCards/HoverCards";

import hero from "../../assets/hero/businessman-standing-against-room-with-large-window-looking-city-hero-image.png";

const Home = () => {
  return (
    <>
      <Container className="px-4 px-md-3">
        <Hero withText={true} hero={hero} />
        <VisionMission visionMissionHome />
        <OurServices />
        <HoverCards />
      </Container>
    </>
  );
};

export default Home;
