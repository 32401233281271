import styled from "styled-components";

export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  box-shadow: none;
  font-size: 18px;
  line-height: 32.5px;
  padding: 20px 40px;
  cursor: pointer;
  border-radius: 15px;
  border: 0;
  transition: 0.3s ease-in-out;
  background-color: ${(props) => props.theme.exploreNowBgColor};
  color: ${(props) => props.theme.background};

  &:hover {
    background-color: ${(props) => props.theme.primary};
  }
`;
