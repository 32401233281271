import { Container } from "react-bootstrap";
import Hero from "../../components/Hero/Hero";

import hero from "../../assets/hero/our-portfolio/our-portfolio.png";
import AboutUsComponent from "../../components/AboutUsComponent/AboutUsComponent";

const OurPortfolio = () => {
  return (
    <>
      <Hero hero={hero} heroOnly position="top" />
      <Container className="px-4 px-md-3">
        <AboutUsComponent ourPortfolio />
      </Container>
    </>
  );
};

export default OurPortfolio;
